/* eslint-disable */
import { Splitter } from "@progress/kendo-react-layout";
import React, { useEffect, useRef, useState } from "react";
import { ENTITYNAME, MILLISECONDSINADAY, PLAYLIST_STATUS } from "../../../framework/constant/constant";
import { useTranslation } from "../../../locale/useTranslation";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import * as API from "../../../framework/API/api";
import { toast } from "react-toastify";
import moment from "moment";
import { PlaylistHeader } from "./PlaylistHeader";
import PlaylistFooter from "./PlaylistFooter";
import { PlaylistCollection } from "./PlaylistCollection";
import { PlaylistOverlayGrid } from "./PlaylistOveralyGrid";
import PlaylistMainTreeNew from "./PlaylistMainTreeNew";
import DigitalSignScreenGroupCollectionSelection from "../DigitalSIgnScreenGroupCollectionSelection";
import { DigitalSign_ContentColumns, DigitalSign_ContentGroupColumns, DigitalSign_OverlaysColumns } from "../../../framework/utility/customColumns";
import { PlaylistHelper } from "./PaylistHelper";

const PlaylistWrapperNew = () => {
  const lang = useTranslation();
  const playlistRef = useRef();

  const defaultTabNumber = "1";

  const [clientInfo, setClientInfo] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [copyPasteDataItem, setCopyPasteDataItem] = useState(0);
  const [selectedState, setSelectedState] = useState([]);
  const [copyPasteCount, setCopyPasteCount] = useState({
    cut: 0,
    copy: 0
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [expandToggle, setExpandToggle] = useState(false);
  const [selectedPlaylistStartDate, setSelectedPlaylistStartDate] = useState(new Date(new Date().setUTCHours(0, 0, 0, 0)).getTime());
  const [selectedPlaylistEndDate, setSelectedPlaylistEndDate] = useState(new Date(new Date(selectedPlaylistStartDate + (MILLISECONDSINADAY * 6)).setUTCHours(23, 59, 59, 999)).getTime());
  const [localStartDate, setLocalStartDate] = useState(new Date(new Date().setUTCHours(0, 0, 0, 0)).getTime());
  const [localEndDate, setLocalEndDate] = useState(new Date(new Date(selectedPlaylistStartDate + (MILLISECONDSINADAY * 6)).setUTCHours(23, 59, 59, 999)).getTime());
  const [newPlaylistData, setNewPlaylistData] = useState({
    Name: "",
    Status: PLAYLIST_STATUS.Standby
  });
  const [tabNumber, setTabNumber] = useState(defaultTabNumber);
  const [nodesList, setNodesList] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [doLoad, setDoLoad] = useState(false);
  const [selectedOverlayAsset, setSelectedOverlayAsset] = useState(null);
  const [showScreenGroupPopup, setShowScreenGroupPopup] = useState(false);
  const [totalDevices, setTotalDevices] = useState({
    online: 0,
    offline: 0
  });
  const isPlaylistLoading = useRef(false);
  const [outSideFocus, _setOutSideFocus] = useState(false);
  const outSideFocusRef = useRef(outSideFocus);
  const setOutSideFocus = (value) => {
    _setOutSideFocus(value);
    outSideFocusRef.current = value;
  }

  const [statusInfo, setStatusInfo] = useState({
    parentCount: 0,
    commercialCount: 0,
    overRunUnderRunCount: 0,
    playlistStartTime: 0,
    playlistEndTime: 0,
  });

  const [playlistData, setPlaylistData] = useState(null);
  const [playlistTimeInfo, setPlaylistTimeInfo] = useState({
    StartDateTime: 0,
    EndDateTime: 0,
    PreviousScheduleEndDateTime: 0,
  });

  const [selectedMedia, setSelectedMedia] = useState([]);
  const [showNewPlaylistDialog, setShowNewPlaylistDialog] = useState(false);
  const [companyId, setCompanyId] = useState(null);

  //splitter variable and onChange function
  const [horizontalPanes, setHorizontalPanes] = React.useState([
    {
      size: "70%",
      collapsible: true,
    },
    {
      collapsible: true,
    },
  ]);

  const [verticalPanes, setVerticalPanes] = React.useState([
    {
      collapsible: false,
    },
    {
      size: "30%",
      collapsible: true,
      collapsed: true,
    },
  ]);

  useEffect(() => {
    loadClientInfo();
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document?.removeEventListener("keydown", handleKeyDown);
    }
  }, []);

  const handleKeyDown = (e) => {
    if (outSideFocusRef.current) return;

    // refresh F5
    if (e.keyCode == 116 || e.key == "F5") {
      e.preventDefault();
      onPlaylistRefresh();
      console.log(`You pressed refresh`);
      return;
    }

    if (e.ctrlKey && e.key == "h") {
      e.preventDefault();
      playlistRef.current.onShowGlobalReplacement();
      console.log(`You pressed find and replace`);
      return;
    }
    // ctrl +
    if (e.ctrlKey && e.key == "=") {
      e.preventDefault();
      playlistRef.current.onExpand({ target: { value: true } });
      console.log(`You pressed ctrl +`);
      return;
    }
    // ctrl -
    if (e.ctrlKey && e.key == "-") {
      e.preventDefault();
      playlistRef.current.onExpand({ target: { value: false } });
      console.log(`You pressed ctrl -`);
      return;
    }
    else {
      return;
    }

  }

  const loadClientInfo = async () => {
    const clientInfoRes = await API.getDataLookup(ENTITYNAME.ClientInfo, { sort: { SID: 1 } });
    setClientInfo(clientInfoRes.data[0]);
  }

  const onHorizontalSplitterChange = (event) => {
    setHorizontalPanes(event.newState);
  };

  const onVerticalSplitterChange = (event) => {
    setVerticalPanes(event.newState);
  };

  const handleFilterDialog = () => {
    if (showFilter) {
      setLocalStartDate(selectedPlaylistStartDate);
      setLocalEndDate(selectedPlaylistEndDate);
    }
    console.log("coming Here Filter");
    setShowFilter(!showFilter);
  };

  const handleNewPlaylistDialog = () => {
    setShowNewPlaylistDialog(!showNewPlaylistDialog);
    setNewPlaylistData({
      Name: "",
      Notes: "",
      Status: PLAYLIST_STATUS.Standby
    });
  };

  const handlePopupConfirm = async () => {

    if (!selectedNode || selectedNode.SID < 0) {
      toast.error("Please select Node", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (localEndDate < localStartDate) {
      toast.error("End Date should be greater than Start Date", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setSelectedPlaylistStartDate(localStartDate);
    setSelectedPlaylistEndDate(localEndDate);
    onPlaylistRefresh();
    handleFilterDialog();
    setExpandToggle(false)
  };

  const onPlaylistRefresh = () => {
    setDoLoad(!doLoad);
  };

  const onDateChange = async (isNext) => {
    if (isPlaylistLoading.current) {
      toast.info("Please wait .. Playlist is already loading", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    let diffInDays = moment(selectedPlaylistEndDate).diff(
      moment(selectedPlaylistStartDate),
      "days"
    );
    if (diffInDays < 1) {
      diffInDays = 1;
    }
    let startDate = selectedPlaylistStartDate;
    let endDate = selectedPlaylistEndDate;
    if (selectedPlaylistStartDate > selectedPlaylistEndDate) {
      startDate = selectedPlaylistEndDate;
      endDate = selectedPlaylistStartDate;
    }
    if (isNext) {
      setSelectedPlaylistStartDate(startDate + (MILLISECONDSINADAY * diffInDays));
      setLocalStartDate(startDate + (MILLISECONDSINADAY * diffInDays));
      setSelectedPlaylistEndDate(endDate + (MILLISECONDSINADAY * diffInDays));
      setLocalEndDate(endDate + (MILLISECONDSINADAY * diffInDays));
    } else {
      setSelectedPlaylistStartDate(startDate - (MILLISECONDSINADAY * diffInDays));
      setLocalStartDate(startDate - (MILLISECONDSINADAY * diffInDays));
      setSelectedPlaylistEndDate(endDate - (MILLISECONDSINADAY * diffInDays));
      setLocalEndDate(endDate - (MILLISECONDSINADAY * diffInDays));
    }
    onPlaylistRefresh();
    setExpandToggle(false);
  };

  const onIsPlaylistLoading = (data) => {
    isPlaylistLoading.current = data;
  };

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const setSelectedStateItem = (item) => {
    setSelectedState(item);
  }

  const selectedNodeItem = (item) => {
    onPlaylistRefresh();
    setSelectedNode(item);
  }

  const handleNewPlaylistCreate = async () => {
    if (newPlaylistData.name.trim().length == 0) {
      toast.error("Please enter Playlist Name", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (selectedNode == null) {
      toast.error("Please select Node", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    // format milliseconds
    let dateTime = parseInt(moment(newPlaylistData.date).utc().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).add(newPlaylistData.time, 'millisecond').valueOf().toFixed(0));
    let data = {
      Name: newPlaylistData.name,
      Notes: newPlaylistData.notes,
      Node: { _id: selectedNode._id, Name: selectedNode.Name },
      DateTime: dateTime,
      TxStatus: newPlaylistData.TxStatus
    }
    let res = await API.saveData(ENTITYNAME.DigitalSignPlaylist, data);
    if (res.success) {
      toast.success('Playlist created successfully', {
        position: toast.POSITION.TOP_RIGHT
      });
      onPlaylistRefresh();
      handleNewPlaylistDialog();
    } else {
      toast.error('Playlist creation failed', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const setSelectedOverlayAssetForGrid = (asset) => {
    setSelectedOverlayAsset({ ...asset });

    setVerticalPanes([
      {
        collapsible: false,
      },
      {
        size: "30%",
        collapsible: true,
        collapsed: asset ? false : true,
      },
    ]);

  }

  const deleteOverlays = (selectedOverlayAsset, overlayIds) => {
    playlistRef.current.deleteOverlays(selectedOverlayAsset, overlayIds);
  }

  const onPlaylistNameChange = async (newName) => {
    if (!playlistData || playlistData?.SID == 0 || newName == playlistData.Name) {
      return;
    }

    let res = await API.saveData(ENTITYNAME.DigitalSignPlaylist, { _id: playlistData._id, SID: playlistData.SID, Name: newName, Company_id: playlistData?.Company_id });
    if (res.success) {
      setPlaylistData({ ...playlistData, Name: newName });
      toast.success("Playlist name updated successfully");
    } else {
      toast.error("Something went wrong");
    }
  }

  const onScreenGroupChange = async (screenGroup) => {
    let res = await API.saveData(ENTITYNAME.DigitalSignPlaylist, { _id: playlistData._id, SID: playlistData.SID, screenGroup: screenGroup.map(s => s._id), Company_id: playlistData?.Company_id });

    if (res.success) {
      toast.success('Screen group changed');
      let item = { ...playlistData, screenGroup: screenGroup }
      setPlaylistData(item);
      await PlaylistHelper.onRefreshPlaylist(item);
      onPlaylistRefresh();
    } else {
      toast.error('Something went wrong');
    }
    setShowScreenGroupPopup(false);
  }

  return (
    <>
      <div className="ScheduleWrapper" style={{ paddingBottom: "25px", position: "relative" }}>
        <PlaylistHeader
          handleFilterDialog={handleFilterDialog}
          onDateChange={onDateChange}
          clientInfo={clientInfo}
          startDate={selectedPlaylistStartDate}
          endDate={selectedPlaylistEndDate}
          selectedNode={selectedNode}
          nodesList={nodesList}
          onPlaylistRefresh={onPlaylistRefresh}
          statusInfo={statusInfo}
          onSearch={(e) => {
            playlistRef.current.onSearch(e);
          }}
          playlistTimeInfo={playlistTimeInfo}
          playlistData={playlistData}
          selectedState={selectedState}
          setOutSideFocus={setOutSideFocus}
          onHeaderExpandChange={(e) => {
            setExpandToggle(e.target.value);
            playlistRef.current.onExpand(e);
          }}
          onCut={() => {
            playlistRef.current.onCut();
          }}
          onCopy={() => {
            playlistRef.current.onCopy();
          }}
          onPaste={() => {
            playlistRef.current.onPaste();
          }}
          onDelete={() => {
            playlistRef.current.onDelete();
          }}
          handleNewPlaylistDialog={handleNewPlaylistDialog}
          onPlaylistNameChange={onPlaylistNameChange}
          setShowScreenGroupPopup={setShowScreenGroupPopup}
          onPlaylistAction={async (dataItem, status) => { await playlistRef.current.onPlaylistAction(dataItem, status) }}
          onSchedulePlaylistClick={() => {
            playlistRef.current.onSchedulePlaylistClick();
          }}
        ></PlaylistHeader>
        <Splitter
          panes={horizontalPanes}
          className="horizontalSplitter"
          onChange={onHorizontalSplitterChange}
          style={{ height: '97.5%' }}
        >
          <Splitter
            panes={verticalPanes}
            orientation="vertical"
            onChange={onVerticalSplitterChange}
          >
            {/* Playlist main grid */}
            <PlaylistMainTreeNew
              ref={playlistRef}
              statusInfo={statusInfo}
              setStatusMessage={setStatusMessage}
              setSelectedState={setSelectedStateItem}
              selectedNode={selectedNode}
              setCopyPasteDataItem={setCopyPasteDataItem}
              doLoad={doLoad}
              outSideFocus={outSideFocus}
              setStatusInfo={setStatusInfo}
              onIsPlaylistLoading={onIsPlaylistLoading}
              playlistTimeInfo={playlistTimeInfo}
              setPlaylistTimeInfo={setPlaylistTimeInfo}
              setPlaylistData={setPlaylistData}
              setExpandToggle={setExpandToggle}
              setCopyPasteCount={setCopyPasteCount}
              setSelectedOverlayAssetForGrid={setSelectedOverlayAssetForGrid}
              onPlaylistRefresh={onPlaylistRefresh}
              setTotalDevices={setTotalDevices}
              startDate={selectedPlaylistStartDate}
              endDate={selectedPlaylistEndDate}
              setCompanyId={setCompanyId}
            />
            <PlaylistOverlayGrid selectedOverlayAsset={selectedOverlayAsset} deleteOverlays={deleteOverlays} />
          </Splitter>
          {/* Playlist Assets */}
          <Box sx={{ width: "97%", typography: "body1" }}>
            <TabContext value={tabNumber}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  variant="scrollable"
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label={lang.digital_sign_content_label} value="1" />
                  <Tab label={lang.digital_sign_overlay_label} value="2" />
                  <Tab label={lang.digital_sign_content_group_label} value="3" />
                </TabList>
              </Box>
              <TabPanel value={"1"}>
                <PlaylistCollection
                  columns={DigitalSign_ContentColumns}
                  title={lang.content_page_breadcrumb}
                  entityname={ENTITYNAME.DigitalSignContent}
                  setOutSideFocus={setOutSideFocus}
                  setSelectedMedia={setSelectedMedia}
                  page_take={20}
                  enablePreview={true}
                  addNew={() => window.open("/home/digitalSignContent?openNewEditForm=true", "_blank")}
                  showOrientationButton={true}
                  Company_id={companyId}
                />
              </TabPanel>
              <TabPanel value={"2"}>
                <PlaylistCollection
                  columns={DigitalSign_OverlaysColumns}
                  title={lang.overlay_page_breadcrumb}
                  entityname={ENTITYNAME.DigitalSignOverlay}
                  setOutSideFocus={setOutSideFocus}
                  setSelectedMedia={setSelectedMedia}
                  page_take={20}
                  addNew={() => window.open("/home/digitalSignBoardOverlay/0", "_blank")}
                  Company_id={companyId}
                />
              </TabPanel>
              <TabPanel value={"3"}>
                <PlaylistCollection
                  columns={DigitalSign_ContentGroupColumns}
                  title={lang.digital_sign_content_group_page_breadcrumb}
                  entityname={ENTITYNAME.DigitalSignContentGroup}
                  setOutSideFocus={setOutSideFocus}
                  setSelectedMedia={setSelectedMedia}
                  page_take={20}
                  addNew={() => window.open("/home/digitalSignContentGroup/0", "_blank")}
                  Company_id={companyId}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Splitter>
      </div>

      <PlaylistFooter
        onPreferenceFilter={() => { }}
        selectedState={selectedState}
        copyPasteCount={copyPasteCount}
        statusInfo={statusInfo}
        statusMessage={statusMessage}
        expandToggle={expandToggle}
        onHeaderExpandChange={(e) => {
          setExpandToggle(e.target.value);
          playlistRef.current.onExpand(e);
        }}
        searchWord={""}
        onPlaylistRefresh={onPlaylistRefresh}
        totalDevices={totalDevices}
        playlistData={playlistData}
        setShowScreenGroupPopup={setShowScreenGroupPopup}
      />
      {showScreenGroupPopup && <DigitalSignScreenGroupCollectionSelection Company_id={companyId} addButtonTitle={'Change'} entityname={ENTITYNAME.DigitalSignScreenGroup} seletedState={playlistData?.screenGroup} closeForm={() => { setShowScreenGroupPopup(false) }} setDataList={onScreenGroupChange} title={'Change Screen Group'} width={"800px"} />}
    </>
  );
};

export default PlaylistWrapperNew;
