/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import * as API_DIGI_SIGN from "../../framework/API/api_digitalSign";
import EditForm from "../../framework/forms/helpercomponents/editForm";
import { ENTITYNAME, MODULE, SOCKET_EVENTS, SOCKET_ACTION, LOGEVENT, PLAYLIST_STATUS, PLAYLIST_SCHEDULE_TYPE, LOCALSTORAGE_KEY, COMPANYTYPE } from "../../framework/constant/constant";
import { Form, useLocation, useNavigate } from "react-router-dom";
import DeleteErrorDialogBox from "../../framework/forms/helpercomponents/alert/DeleteErrorDialogBox";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { collectionPlaylistMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { UserPreference } from "../userPreference/UserPreference";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import MyStatusCell from "../../components/MyStatusCell";
import { getter } from "@progress/kendo-data-query";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { utility } from "../../framework/utility/utilityProvider";
import socket from "../../framework/socket/socket";
import ActivateScreenPoup from "./activateScreenPoup";
import { ArrayCellFieldName } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCellFieldName";
import { PlaylistHelper } from "./Playlist/PaylistHelper";
import SchedulePlaylistPopup from "./Playlist/SchedulePlaylistPopup";
import { ScheduleTypeIconCell } from "../../framework/forms/helpercomponents/CustomGridCells/IconCell";
import { ButtonCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/ButtonCommandCell";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DigitalSignScreenGroupCollectionSelection from "./DigitalSIgnScreenGroupCollectionSelection";
import { ContentArrayImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ContentArrayImageCell";
import BossDialog from "../../components/BossDialog";
import { EditPageHeader } from "../../components/EditPageHeader";
import { FormElement } from "@progress/kendo-react-form";
import MyCompanyCell from "./component/MyCompanyCell";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

export const HeaderPlaylistCollection = (props) => {
    //possible props
    let page_take = props.page_take ?? 50;
    let showAdd = props.showAdd ?? true;
    let showBack = props.showBack ?? true;
    let showCommandCell = props.showCommandCell ?? true;
    let showTitle = props.showTitle ?? true;
    let gridRowRender = props.gridRowRender;
    let CustomEditForm = props.customEditForm ?? EditForm;
    let filterable = props.filterable ?? false;
    let sortable = props.sortable ?? true;
    let isPageable = props.isPageable ?? true;
    let wherestatement = props.wherestatement ?? [];
    let sort = props.sort ?? { SID: 1 };
    let entityName = props.entityname;
    let hideArchiveProps = props.hideArchive ?? false;
    let enableMutliSelect = props.enableMutliSelect ?? false;
    let module = props?.module;
    let showLogs = props.showLogs ?? false;
    let doLoad = props.isLoading ?? true;

    // example custome columns[]
    const [gridData, setGridData] = useState([]);
    const [total, setTotal] = useState(1);
    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState({ SID: 1 });
    const [deleteError, setDeleteError] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isNoRecords, setIsNoRecords] = useState(false);
    const [showPreferences, setShowPreferences] = useState(false);
    const [dataColumns, setDataColumns] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedState, setSelectedState] = useState({});
    const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
    const [gridSort, setGridSort] = useState([initialSort]);
    const [showActivateScreenPopup, setShowActivateScreenPopup] = useState(false);

    const [currentPlaylistItem, setCurrentPlaylistItem] = useState({});
    const [showSchedulePopup, setShowSchedulePopup] = useState(false);
    const [showScreenGroupPopup, setShowScreenGroupPopup] = useState(false);
    const [groups, setGroups] = useState([]);

    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
    const companies = utility.getValue(LOCALSTORAGE_KEY.COMPANIES);
    const [showIsEnterprise, setShowIsEnterprise] = useState(false);
    const [filterDataItem, setFilterDataItem] = useState({
        Status: { _id: 0, Description: 'All' },
        Group: { _id: 0, Name: 'All' },
        Company: { _id: 0, Name: 'All' }
    })
    const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);
    const [selectedCompany, setSelectedCompany] = useState((companies?.length > 0 && companies[0]) ?? {});

    const selectedItemsRef = useRef([]);
    const setSelectedItem = (items) => {
        selectedItemsRef.current = items;
    }

    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });

    //only to get wherestatement from href cell
    const location = useLocation();
    const lang = useTranslation();
    const isFunctionCalled = useRef(false);

    useEffect(() => {
        if (location.state?.openNewEditForm && !isFunctionCalled.current) {
            isFunctionCalled.current = true;
            createNewPlaylist();
        }
    }, [location.state?.openNewEditForm]);


    let href = location.state ? location.state.href : false;
    if (href) {
        wherestatement = location.state.wherestatement;
    }

    useEffect(() => {
        doLoad ? setIsLoading(true) : setIsLoading(false);
        setPage({ skip: 0, take: page_take });
        loaddata({ per_page: page_take, current_page: 1 }, wherestatement, searchText);
        loadCombo();
    }, []);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        let sortEvent = gridSort[0] ?? initialSort;

        let newWhereStatement = [...wherestatement];

        if (filterDataItem.Group._id != 0) {
            newWhereStatement = [...newWhereStatement, ["screenGroup", "in", [filterDataItem.Group._id]]]
        }

        if (filterDataItem.Status._id != 0) {
            newWhereStatement = [...newWhereStatement, ["Status", "=", filterDataItem.Status._id]]
        }

        if (filterDataItem.Company._id != 0) {
            newWhereStatement = [...newWhereStatement, ["Company_id", "=", filterDataItem.Company._id]]
        }

        if (searchText != "") {
            const timeOut = setTimeout(() => {
                setIsLoading(true);
                loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, newWhereStatement, searchText);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setIsLoading(true);
            setPage({ skip: 0, take: page_take });
            loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, newWhereStatement, searchText);
        }
    }, [searchText]);

    const loadCombo = async () => {
        const groupRes = await API_DIGI_SIGN.getDataLookup(ENTITYNAME.DigitalSignScreenGroup, { query: ["Archive", "=", false] });
        setGroups(groupRes.data);
    }

    const onKillUserSession = (userData) => {
        delete userData["Module"];
        console.log(userData);
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.KILL_USER_SESSION, module: MODULE.ALL, data: userData })
        toast.success(`${userData.name} session has been killed successfully`, { position: toast.POSITION.TOP_RIGHT });
    }

    const enterEdit = (item) => {
        console.log("onEnterEdit");
        if (item.copy) {
            createPlaylistCopy(item._id);
        } else {
            navigate(`/home/PlaylistItem/${item._id}`);
        }
    };

    const createPlaylistCopy = async (playlistId) => {
        let playlistRes = await API_DIGI_SIGN.createDuplicatePlaylist(playlistId);
        if (!playlistRes.success) {
            toast.error('Error creating Duplicate Playlist. Try again');
            return;
        }
        navigate(`/home/PlaylistItem/${playlistRes.data}`);
    }

    //onSubmit from EDIRFORM
    const handleSubmit = async (dataItem, dataToSave) => {
        console.log("handleSUbmit");
        //if datatosave is null then dataitem is datasave
        dataToSave = dataToSave == null ? dataItem : dataToSave;

        let isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

        if (!isDuplicate) {
            setOpenForm(true);
            toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let res = await API_DIGI_SIGN.saveData(props.entityname, dataToSave);
        console.log(res);
        if (dataItem?.IsDefault) {
            await API.setAsDefault(entityName, dataItem?._id)
        }
        if (res.success) {
            if (showLogs) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: module, data: res.data, message: res.message };
                API.SaveLogs(logData);
            }
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
        utility.deleteLocalStorageItem(props.entityname)
        //to load grid with new data
        loaddata({ per_page: page_take, current_page: 1 }, wherestatement, searchText);
        setOpenForm(false);
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const handleDelete = async (dataItem) => {
        console.log(dataItem);
        onPlaylistDelete(dataItem);
    };

    const onPlaylistDelete = async (dataItem) => {
        let res = await API_DIGI_SIGN.deletePlaylist(dataItem._id);
        if (res.success) {
            // sent event to socket to stop playlist
            toast.success('Playlist deleted successfully');
            refreshCollection();
            PlaylistHelper.handlePlaylistAction(dataItem, PLAYLIST_STATUS.UnPublished);
        } else {
            toast.error(res.message);
        }
    }

    const handlePlaylistAction = async (dataItem, status) => {

        let res = await PlaylistHelper.handlePlaylistAction(dataItem, status);

        if (res.success) {
            let localData = [...gridData];
            let index = localData.findIndex((x) => x._id == dataItem._id);
            localData[index] = { ...dataItem, Status: status };
            setGridData(localData);
        } else {
            console.log(res)
        }

    }

    const handlePlaylistRefresh = async (item) => {
        await PlaylistHelper.onRefreshPlaylist(item);
    }

    const onSchedulePlaylistClick = (dataItem) => {
        setShowSchedulePopup(true);
        setCurrentPlaylistItem(dataItem);
    }

    const handleManageScreenGroup = async (dataItem) => {
        setCurrentPlaylistItem(dataItem);
        setShowScreenGroupPopup(true);
    }

    const onScreenGroupChange = async (screenGroup) => {
        let res = await API_DIGI_SIGN.saveData(ENTITYNAME.DigitalSignPlaylist, { _id: currentPlaylistItem._id, SID: currentPlaylistItem.SID, screenGroup: screenGroup.map(s => s._id), Company_id: currentPlaylistItem?.Company_id });
        if (res.success) {
            toast.success("Screen group changed", {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.log(res)
            PlaylistHelper.onRefreshPlaylist({...currentPlaylistItem, screenGroup: screenGroup});
            refreshCollection();

        } else {
            toast.error('Something went wrong', {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.log(res)

        }
        setShowScreenGroupPopup(false);
    }

    const handleArchive = async (dataItem) => {
        console.log("Here");
        var resArchiveUpdated = await API_DIGI_SIGN.saveData(entityName, {
            ...dataItem,
            Archive: !dataItem.Archive
        })

        if (resArchiveUpdated.success) {
            console.log(`Archive is been updated to ${!dataItem.Archive}`);
            if (module) {
                let logData = { event: !dataItem.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: module, data: { ...dataItem, Archive: !dataItem.Archive }, message: resArchiveUpdated.message };
                API.SaveLogs(logData);
            }
            let sortEvent = gridSort[0] ?? initialSort;
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText);
            socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
            utility.deleteLocalStorageItem(entityName);
        } else {
            console.log(resArchiveUpdated.message);
        }
    }

    const onCloseUserPrefence = () => {
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText);
        setShowPreferences(!showPreferences);
    }

    const createNewPlaylistWithCompany = async () => {
        setShowIsEnterprise(true);
    }

    const cancelEdit = () => {
        setShowIsEnterprise(false)
    }

    const createNewPlaylist = async () => {
        if (isEnterpriseLogin && (!selectedCompany || Object.keys(selectedCompany).length == 0)) {
            toast.info(lang.please_select_company_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return;
        }
        let playlistId = await createDummyPlaylist();
        if (playlistId) {
            navigate(`/home/PlaylistItem/${playlistId}`);
        }
    }

    const createDummyPlaylist = async () => {
        let screenGroup = [];
        let playlistName = 'Untitled Playlist';

        let screenGroupRes = await API_DIGI_SIGN.getData(ENTITYNAME.DigitalSignScreenGroup);
        if (!screenGroupRes.success) {
            toast.error('Error creating new Playlist. Try again');
            return null;
        }

        let Company_id = isEnterpriseLogin ? selectedCompany?._id : company?._id;
        let screenGroupFromDB = screenGroupRes.data.filter(s => s.Company_id == Company_id);
        if (screenGroupFromDB.length > 0) {
            screenGroup.push(screenGroupFromDB[0]);
        } else {
            let screenGroupSaveRes = await API_DIGI_SIGN.saveData(ENTITYNAME.DigitalSignScreenGroup, { SID: 0, Name: 'Default', Company_id: Company_id });
            if (!screenGroupSaveRes.success) {
                toast.error('Error creating new Playlist. Try again');
                return null;
            } else {
                screenGroup.push(screenGroupSaveRes.data);
            }
        }
        //PLAYLIST DATA SCHEMA
        let saveData = {
            SID: 0,
            Name: playlistName,
            Status: PLAYLIST_STATUS.Standby,
            screenGroup: screenGroup.map(s => s._id),
            Schedule: {
                ScheduleType: PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover,
                StartDate: null,
                EndDate: null,
                isNoEndDate: true,
            },
            Company_id: Company_id
        }
        let res = await API_DIGI_SIGN.saveData(ENTITYNAME.DigitalSignPlaylist, saveData);
        if (res.success) {
            toast.success('New playlist created');
            return res.data?._id;
        } else {
            toast.error('Error creating new Playlist. Try again');
            return null;
        }
    }
    //Edit Row
    const MyCommandCell = (props) => (

        <MenuCommandCell
            {...props}
            enterEdit={enterEdit}
            item={editItem}
            onDelete={handleDelete}
            onSchedulePlaylist={onSchedulePlaylistClick}
            onManageScreenGroup={handleManageScreenGroup}
            onPlaylistAction={handlePlaylistAction}
            onRefreshPlaylist={handlePlaylistRefresh}
            myMenuModel={collectionPlaylistMenuModel}

        />
    );

    const loaddata = async (pagination, wherestatement, searchText) => {
        setIsLoading(true);
        let finalSort = pagination?.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : { SID: 1 };
        let newWhereStatement = [...wherestatement];
        let res = await API_DIGI_SIGN.getPlaylists(
            { query: newWhereStatement, page: pagination.current_page, perPage: pagination.per_page, archive: false, sort: finalSort },
            searchText
        );
        console.log(res);
        if (res.success) {
            console.log(res.data);
            setGridData(res.data);
            setTotal(res?.pagination?.total);
        } else {
            toast.error(res.message);
            setIsNoRecords(true);
            setGridData([]);
        }
        setIsLoading(false)
    };

    const handleErrorDialogClose = () => {
        setDeleteError(!deleteError);
        setDeleteErrorMessage("");
    }

    const pageChange = async (e) => {
        let current_page = e.page.skip / e.page.take + 1;
        let sortEvent = gridSort[0] ?? initialSort;
        await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText);
        setPage(e.page);
        cancelSelectedItem()
    };

    const refreshCollection = () => {
        setGridSort([initialSort]);
        loaddata({ per_page: page_take, current_page: 1 }, wherestatement, '');
        setFilterDataItem({
            Status: { _id: 0, Description: 'All' },
            Group: { _id: 0, Name: 'All' },
            Company: { _id: 0, Name: 'All' },
        })
    }

    const onSortChange = (e) => {
        setGridSort(e.sort)
        let sortEvent = e.sort[0] ?? sort;
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText);
    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);

        let selectedIds = Object.keys(newSelectedState);
        let tempSelected = [];

        selectedIds.map((value) => {
            if (newSelectedState[value]) {
                let filter = gridData.find((obj) => obj._id == value);
                tempSelected.push(filter);
            }
        });

        setSelectedItem(tempSelected);
    };


    const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);

        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];

        if (checked) {
            selectedIds.map((value) => {
                if (newSelectedState[value]) {
                    var filtered = gridData.find((obj) => obj._id == value);
                    tempSelected.push(filtered);
                }
            });
        }
        setSelectedItem(tempSelected);
    };

    const cancelSelectedItem = () => {
        setSelectedItem([]);
        setSelectedState({});
    }

    let timeOut;
    const handleColumnResize = (event) => {
        let lockedCol = event.columns.filter((item) => item?.locked).length ?? 1;
        try {
            if (event.index == 0) return;
            if (dataColumns == undefined || dataColumns.length == 0 || dataColumns.length < event.index) return;
            let column = dataColumns[event.index - lockedCol];
            let payload = {
                entityName: column?.entityName,
                name: column?.name,
                width: event.newWidth
            };
            clearTimeout(timeOut);
            timeOut = setTimeout(() => {
                API.updateUserPreferenceColumnWidth(payload);
            }, 700);
        } catch (error) {
            console.error("Error handling column resize:", error);
        }
    };

    const handleFiltersChange = (e) => {

        setIsLoading(true);

        let localFilterItem = { ...filterDataItem, [e.target.name]: e.target.value }

        setFilterDataItem({ ...filterDataItem, [e.target.name]: e.target.value });

        let newWhereStatement = [...wherestatement];

        if (localFilterItem.Group._id != 0) {
            newWhereStatement = [...newWhereStatement, ["screenGroup", "in", [localFilterItem.Group._id]]]
        }

        if (localFilterItem.Status._id != 0) {
            newWhereStatement = [...newWhereStatement, ["Status", "=", localFilterItem.Status._id]]
        }

        if (localFilterItem.Company._id != 0) {
            newWhereStatement = [...newWhereStatement, ["Company_id", "=", localFilterItem.Company._id]]
        }

        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, newWhereStatement, searchText);
        setPage({ skip: 0, take: page_take });
    }

    const MyPlaylistStatuscell = (props) => (
        <div>
            <MyStatusCell style={{ width: '100px', position: "relative", top: "3px" }} title={utility.convertCamelCaseToWords(utility.getKeyByValue(PLAYLIST_STATUS, props.dataItem.Status))} tileBackColor="#508D4E" />
        </div>
    )

    const MyButtonCommandCell = (props) => {
        const { onClick } = props;
        const [isPlayActionLoading, setIsPlayAction] = useState(false);
        return (
            <ButtonCommandCell
                {...props}
                isLoading={isPlayActionLoading}
                onClick={async (...props) => {
                    setIsPlayAction(true);
                    await onClick(...props);
                    setIsPlayAction(false);
                }}
            />
        );
    };

    const commandCellProps = {
        onClick: handlePlaylistAction,
    };

    return (
        <div>
            {showTitle && (
                <h1 className="page-title txt-color-bludeDark">
                    <i
                        className="fa fa-table marlr"
                        style={{ fontSize: "20px", color: "GrayText" }}
                    ></i>
                    <span>Playlist</span>
                </h1>
            )}
            <div className="flex-container">
                <div className="martb mar">
                    {showBack && (
                        <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
                    )}
                    {showAdd && (
                        <AddRoundButton onClick={isEnterpriseLogin ? createNewPlaylistWithCompany : createNewPlaylist} />
                    )}
                </div>
                <div className="flex-container-reverse flex-item-auto">
                    <div className="input-group input-group w-300 martb" >
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>
                    <div className="mt-1" style={{ paddingTop: "12px" }}>
                        <RefreshButton
                            onClick={refreshCollection}
                        />
                    </div>
                    <div className="d-inline col-2" style={{ marginTop: '-9px' }}>
                        <label htmlFor="Node">{lang.group_label}</label>
                        <DropDownList
                            data={[{ _id: 0, Name: 'All' }, ...groups]}
                            style={{ backgroundColor: 'white' }}
                            name="Group"
                            textField="Name"
                            dataItemKey="_id"
                            value={filterDataItem.Group}
                            onChange={(e) => handleFiltersChange(e)}
                        />
                    </div>
                    <div className="d-inline col-2" style={{ marginTop: '-9px' }}>
                        <label htmlFor="Node">{lang.status_label}</label>
                        <DropDownList
                            data={[{ _id: 0, Description: 'All' }, ...Object.keys(PLAYLIST_STATUS).map((x) => { return { _id: PLAYLIST_STATUS[x], Description: x } })]}
                            style={{ backgroundColor: 'white' }}
                            name="Status"
                            textField="Description"
                            dataItemKey="_id"
                            value={filterDataItem.Status}
                            onChange={(e) => handleFiltersChange(e)}
                        />
                    </div>
                    {isEnterpriseLogin && <div className="mr-2" style={{ marginTop: '-9px', width: '150px' }}>
                        <label htmlFor="TabView">{lang.company_label}</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={[
                                { _id: 0, Name: "All" },
                                ...companies
                            ]}
                            name="Company"
                            textField="Name"
                            dataItemKey="_id"
                            value={filterDataItem.Company}
                            onChange={handleFiltersChange}
                        />
                    </div>}
                </div>
            </div>
            {isLoading && <Loader />}
            {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}
            {!isLoading && !isNoRecords &&
                <Grid
                    style={props.gridstyle ?? {
                        height: "76vh",
                    }}
                    sort={gridSort}
                    sortable={sortable}
                    onSortChange={onSortChange}
                    rowRender={gridRowRender}
                    filterable={filterable}
                    pageable={isPageable}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChange}
                    total={total}
                    resizable={true}
                    onColumnResize={handleColumnResize}
                    data={
                        gridData.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: enableMutliSelect,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                    }}
                    dataItemKey={DATA_ITEM_KEY}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                >

                    {/* Multi Select Checkbox row */}
                    {enableMutliSelect && (<GridColumn
                        field={SELECTED_FIELD}
                        width="50px"
                        headerSelectionValue={
                            gridData.findIndex(
                                (item) => !selectedState[idGetter(item)]
                            ) === -1
                        }
                        locked={true}
                        filterable={false}
                    />
                    )}

                    {/* Edit Row */}
                    {showCommandCell && (
                        <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
                    )}
                    <GridColumn field="Schedule.ScheduleType" title="Type" width={60} cell={ScheduleTypeIconCell} />
                    <GridColumn field="Name" title="Name" width={200} />
                    <GridColumn field="Status" title="Status" width={120} cell={MyPlaylistStatuscell} />
                    <GridColumn field="playlistItem" title="Playlist Items" width={350} cell={ContentArrayImageCell} />
                    <GridColumn field="screenGroup" title="Screen Group" width={350} cell={ArrayCellFieldName} />
                    <GridColumn
                        title="Actions"
                        cell={(props) => <MyButtonCommandCell {...props} {...commandCellProps} />}
                        width="120px"
                    />
                    {isEnterpriseLogin && <GridColumn
                        field="Company_id"
                        title={"Company"}
                        cell={MyCompanyCell}
                        width={200}
                    />}
                </Grid>}

            {openForm && (
                <CustomEditForm
                    {...props} //to get other props from customEditForm
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    refresh={refreshCollection}//to refresh the collection when data added
                    item={editItem}
                    dataColumns={dataColumns}
                />
            )}

            {deleteError &&
                <DeleteErrorDialogBox message={`Data of ${props.title ?? props.entityname} con Not be deleted  Due to Foreign Key Inclusion`} onClose={handleErrorDialogClose} errorMessage={deleteErrorMessage} title={props.title} />}

            {showPreferences && <UserPreference entityName={entityName} preferenceColumnENntityName={props.preferenceEntityName} handleClose={onCloseUserPrefence} />}

            {showActivateScreenPopup && <ActivateScreenPoup onClose={(refresh = false) => {
                setShowActivateScreenPopup(false);
                if (refresh) {
                    refreshCollection();
                }
            }} />}

            {showSchedulePopup && <SchedulePlaylistPopup playlistItem={currentPlaylistItem} close={() => setShowSchedulePopup(false)} refresh={refreshCollection} />}

            {showScreenGroupPopup && <DigitalSignScreenGroupCollectionSelection Company_id={currentPlaylistItem?.Company_id} addButtonTitle={'Change Screen Group'} entityname={ENTITYNAME.DigitalSignScreenGroup} closeForm={() => { setShowScreenGroupPopup(false) }} setDataList={onScreenGroupChange} seletedState={currentPlaylistItem?.screenGroup} title={'Change Screen Group'} width={"800px"} />}

            {showIsEnterprise && isEnterpriseLogin && <BossDialog
                title={"Select Company"}
                onClose={cancelEdit}
                width={420}
            >
                <EditPageHeader onSubmit={() => { createNewPlaylist() }} onCancel={cancelEdit} showTitle={false} />
                <div className="row mt-2">
                    <div className='col-12'>
                        <label htmlFor="TabView">{lang.company_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={companies}
                            name="Company"
                            textField="Name"
                            dataItemKey="_id"
                            value={selectedCompany}
                            onChange={(e) => setSelectedCompany(e.target.value)}
                        />
                    </div>
                </div>
            </BossDialog>}
        </div>
    );
};